import React from "react";
import logo from "../../assets/images/zomegle.png";

const Logo = ({ logoColor = "text-white" }) => {
    return (
        // <h1 className={`text-2xl sm:text-title-xl2 ${logoColor}`}>

        // </h1>
        <img src={logo} className="w-[200px] mx-auto" alt="logo" />
    );
};

export default Logo;
