import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { searchUsers } from '../../redux/actions/requestAction';
import Loader from '../../components/Loader/Loader';
import UserCard from '../../components/UserCard/UserCard';
import { useSwipeable } from 'react-swipeable';
import UserModel from '../../components/UserModel/UserModel';

const Dashboard = () => {
    const dispatch = useDispatch();
	const userSearch = useSelector(state => state.request);
    const [currentUserIndex, setCurrentUserIndex] = useState(null);
    const handlers = useSwipeable({
        onSwipedLeft: () => setCurrentUserIndex((index) => (index + 1) % userSearch?.searchedUsers.length),
        onSwipedRight: () => setCurrentUserIndex((index) => (index - 1 + userSearch?.searchedUsers.length) % userSearch?.searchedUsers.length),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
      });

    const [search, setSearch] = useState("");
    const [age, setAge] = useState(0);

    const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(searchUsers({
            search: search,
            age: age,
        }));
	};

    useEffect(() => {
        dispatch(searchUsers({
            search: search,            
            age: age,
        }));
        //eslint-disable-next-line
    },[]);

    const handleSuccess = () => {
        dispatch(searchUsers({
            search: search,            
            age: age,
        }));
    }

    return (
        <div>
            <div className="mb-6 flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between">
                <h2 className="text-title-md2 font-semibold text-black dark:text-white">Dashboard</h2>
            </div>
            <div className="grid grid-cols-1 gap-9">
                <div className="flex flex-col gap-9">
                    <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                        <form onSubmit={handleSubmit}>
                            <div className="flex flex-col md:flex-row justify-center md:items-center gap-3 md:gap-5.5 p-6.5">
                                <div className="flex-1 flex flex-col md:flex-row justify-center md:items-center gap-3 md:gap-5.5 p-3">
                                    <label className="block text-black dark:text-white">Search</label>
                                    <input 
                                        type="text" 
                                        placeholder="Search" 
                                        className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary" 
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                </div>
                                <div className="flex flex-col md:flex-row justify-center md:items-center gap-3 md:gap-5.5 p-3">
                                    <label className="block text-black dark:text-white">Age</label>
                                    <select 
                                        className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary" 
                                        value={age}
                                        onChange={(e) => setAge(e.target.value)}
                                    >
                                        <option value={0}>
                                            All
                                        </option>
                                        {Array.from({ length: 60 - 18 + 1 }, (_, i) => i + 18).map(age => (
                                            <option key={age} value={age}>
                                                {age}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <button type='submit' className="flex justify-center rounded bg-primary py-3 px-8 font-medium text-gray">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-9 py-4 md:py-6 2xl:py-10">
                {
                    userSearch?.searchedUsers?.map((user, index) => {
                        return (
                            <UserCard user={user} key={user._id} successAction={handleSuccess} setCurrentUserIndex={setCurrentUserIndex} index={index}/>
                        );
                    })
                }
               
            </div>
            {
                currentUserIndex !== null && <div {...handlers}><UserModel user={userSearch?.searchedUsers[currentUserIndex]} setCurrentUserIndex={setCurrentUserIndex} /></div>
            }
            <Loader loading={ userSearch.loading } />
        </div>
    )
}

export default Dashboard