import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../components/Sidebar/Sidebar';
import Header from '../components/Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import { notificationCount, onlineFriends, userUpdateLocation } from '../redux/actions/userAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment/moment';
import like_image from '../assets/images/ind3-bg-img-2.png';
import Loader from '../components/Loader/Loader';

const DefaultLayout = () => {
	const userLoginData = useSelector(state => state.user);
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const [showParticularTimePopUp, setShowParticularTimePopUp] = useState(false);

	const [socket, setSocket] = useState(null);
    const token = localStorage.getItem('access_token');

	const dispatch = useDispatch();

	useEffect(() => {
        if(userLoginData.isLoggedIn){
            const newSocket = io(process.env.REACT_APP_API_URL);
            setSocket(newSocket);

            newSocket.on('connection', () => {
                console.log('Connected to server');
            });

            newSocket.emit('user_online', {
                token: token
            });

            newSocket.on('notification_count', (count) => {
				dispatch(notificationCount(count));
            });

            newSocket.on('online_friends', (friends) => {
				dispatch(onlineFriends(friends));
            });

			newSocket.on('new_message', (data) => {
				toast.success(data.message, {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				})
            });

			newSocket.on('like', (data) => {
				toast.success(data.message, {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				})
            });

			if((!userLoginData.user.particular_time_popup || moment().diff(moment(userLoginData.user.particular_time_popup), 'days') > 15) && moment().diff(moment(userLoginData.user.timestamp), 'days') > 15){
				setShowParticularTimePopUp(true);
				newSocket.emit('particular_time_popup', { token });
			}

			const handleBeforeUnload = () => {
				newSocket.emit('user_offline', {
                    token: token
                });
                newSocket.disconnect();
			};

			const getLocation = () => {
				if ("geolocation" in navigator) {
					navigator.geolocation.getCurrentPosition(
						(position) => {
							const { latitude, longitude } = position.coords;
							dispatch(userUpdateLocation({
								lat: latitude,
								long: longitude,
							}));
						},
						(error) => {
							alert(error.message);
						}
					);
				} else {
					 alert("Geolocation is not supported in your browser.");
				}
			}
		
			window.addEventListener('beforeunload', handleBeforeUnload);

			getLocation();
		
			return () => {
				window.removeEventListener('beforeunload', handleBeforeUnload);
				newSocket.emit('user_offline', {
                    token: token
                });
                newSocket.disconnect();
			};
        }
        //eslint-disable-next-line
    }, []);

	useEffect(() => {
		const handleBeforeUnload = async (e) => {
			try {
				await socket.emit('user_offline', {
                    token: token
                });
                await socket.disconnect();
			} catch (error) {
				console.error('Error making user offline:', error);
			}
		};
	
		window.addEventListener('beforeunload', handleBeforeUnload);
	
		return () => {
		  window.removeEventListener('beforeunload', handleBeforeUnload);
		};
		//eslint-disable-next-line
	}, [socket]);

	return (
		<div className="dark:bg-boxdark-2 dark:text-bodydark">
			<div className="flex h-screen overflow-hidden">
				<Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
				<div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
					<Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
					<main>
						<div className="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
							<Outlet />
						</div>
					</main>
				</div>
			</div>
			<ToastContainer />
			{
				showParticularTimePopUp && (
					<div className="relative z-999999" aria-labelledby="modal-title" role="dialog" aria-modal="true">
						<div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity z-99999 w-screen h-screen"></div>

						<div className="fixed inset-0 z-999999 w-screen overflow-y-auto">
							<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
								<div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg pt-12 pb-5">
									<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
										<h3 className="text-xl font-semibold leading-6 text-gray-900 text-center text-[#D40073]">Someone has liked your profile!</h3>
									</div>
									<img src={like_image} className='py-5 w-full px-10' alt='like user'/>
									<div className="bg-gray-50 px-4 py-3 flex sm:px-6 justify-center">
										<button onClick={(e) => {
											setShowParticularTimePopUp(false);
										}} type="button" className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				)
			}
			<Loader loading={userLoginData?.loading} />
		</div>
  	);
};

export default DefaultLayout;
