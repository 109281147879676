// userActions.js
import axios from 'axios';

export const CHANNEL_LIST_REQUEST = 'CHANNEL_LIST_REQUEST';
export const CHANNEL_LIST_SUCCESS = 'CHANNEL_LIST_SUCCESS';
export const CHANNEL_LIST_FAILURE = 'CHANNEL_LIST_FAILURE';

export const CHANNEL_CREATE_REQUEST = 'CHANNEL_CREATE_REQUEST';
export const CHANNEL_CREATE_SUCCESS = 'CHANNEL_CREATE_SUCCESS';
export const CHANNEL_CREATE_FAILURE = 'CHANNEL_CREATE_FAILURE';

export const CHANNEL_REQUEST = 'CHANNEL_REQUEST';
export const CHANNEL_SUCCESS = 'CHANNEL_SUCCESS';
export const CHANNEL_FAILURE = 'CHANNEL_FAILURE';

export const ACTION_REQUEST = 'ACTION_REQUEST';
export const ACTION_SUCCESS = 'ACTION_SUCCESS';
export const ACTION_FAILURE = 'ACTION_FAILURE';

export const getChannels = () => {
    const token = localStorage.getItem('access_token');

    return async (dispatch) => {
        dispatch({ type: CHANNEL_LIST_REQUEST });
    
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/channel`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            if (response.data.success) {
                dispatch({ type: CHANNEL_LIST_SUCCESS, payload: response.data });
            } else if (response.data.errors) {
                dispatch({ type: CHANNEL_LIST_FAILURE, payload: response.data.message });
            }
        } catch (error) {
            dispatch({ type: CHANNEL_LIST_FAILURE, payload: 'An error occurred during searching users.' });
        }
    };
};

export const getChannel = (channel_id) => {
    const token = localStorage.getItem('access_token');

    return async (dispatch) => {
        dispatch({ type: CHANNEL_REQUEST });
    
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/channel/${channel_id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            if (response.data.success) {
                dispatch({ type: CHANNEL_SUCCESS, payload: response.data });
            } else if (response.data.errors) {
                dispatch({ type: CHANNEL_FAILURE, payload: response.data.message });
            }
        } catch (error) {
            dispatch({ type: CHANNEL_FAILURE, payload: 'An error occurred during searching users.' });
        }
    };
};

export const createChannel = (channelData) => {
    const token = localStorage.getItem('access_token');

    return async (dispatch) => {
        dispatch({ type: CHANNEL_CREATE_REQUEST });
    
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/channel/create`, channelData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            if (response.data.message) {
                dispatch({ type: CHANNEL_CREATE_SUCCESS, payload: response.data });
            } else if (response.data.errors) {
                dispatch({ type: CHANNEL_CREATE_FAILURE, payload: response.data.errors });
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                dispatch({ type: CHANNEL_CREATE_FAILURE, payload: error.response.data.errors });
            } else {
                dispatch({ type: CHANNEL_CREATE_FAILURE, payload: { message: 'An error occurred during registration.'} });
            }
        }
    };
};

export const addUser = (channel_id, user_id) => {
    const token = localStorage.getItem('access_token');

    return async (dispatch) => {
        dispatch({ type: ACTION_REQUEST });
    
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/channel/${channel_id}/user/${user_id}/add`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            if (response.data.success) {
                dispatch({ type: ACTION_SUCCESS, payload: response.data });
            } else if (response.data.errors) {
                dispatch({ type: ACTION_FAILURE, payload: response.data.message });
            }
        } catch (error) {
            dispatch({ type: ACTION_FAILURE, payload: 'An error occurred during searching users.' });
        }
    };
};

export const removeUser = (channel_id, user_id) => {
    const token = localStorage.getItem('access_token');

    return async (dispatch) => {
        dispatch({ type: ACTION_REQUEST });
    
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/channel/${channel_id}/user/${user_id}/remove`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            if (response.data.success) {
                dispatch({ type: ACTION_SUCCESS, payload: response.data });
            } else if (response.data.errors) {
                dispatch({ type: ACTION_FAILURE, payload: response.data.message });
            }
        } catch (error) {
            dispatch({ type: ACTION_FAILURE, payload: 'An error occurred during searching users.' });
        }
    };
};

export const leaveChannel = (channel_id) => {
    const token = localStorage.getItem('access_token');

    return async (dispatch) => {
        dispatch({ type: ACTION_REQUEST });
    
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/channel/${channel_id}/leave`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            if (response.data.success) {
                dispatch({ type: ACTION_SUCCESS, payload: response.data });
            } else if (response.data.errors) {
                dispatch({ type: ACTION_FAILURE, payload: response.data.message });
            }
        } catch (error) {
            dispatch({ type: ACTION_FAILURE, payload: 'An error occurred during searching users.' });
        }
    };
};


