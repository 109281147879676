import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import ReactImageGallery from "react-image-gallery";
import { Biodata } from "../UserCard/UserCard";

const UserModel = ({ user, setCurrentUserIndex }) => {
    return (
        <div className="">
            <div
                className="z-999999"
                aria-labelledby="modal-title"
                role="dialog"
                aria-modal="true"
            >
                <div className="fixed top-0 left-0 bg-black bg-opacity-75 transition-opacity z-99999 w-screen h-screen"></div>
                <div className="fixed top-0 left-0 z-999999 w-screen h-screen overflow-y-auto">
                    <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
                        <div className="transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full sm:max-w-lg py-4">
                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left flex justify-between items-center">
                                <h3 className="text-xl font-semibold leading-6 text-gray-900 text-black px-4 sm:px-0">
                                    {user?.name}
                                </h3>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setCurrentUserIndex(null);
                                    }}
                                    className="outline-none px-5 text-2xl text-black"
                                >
                                    <AiOutlineClose />
                                </button>
                            </div>
                            <Biodata user={user} />
                            {user?.images.length > 0 && (
                                <div className="bg-gray-50 px-4 py-3 flex sm:px-6 justify-center">
                                    <ReactImageGallery
                                        items={user?.images.map((image) => ({
                                            original: image,
                                            thumbnail: image,
                                        }))}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserModel;
