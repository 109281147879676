import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getSuggestedUsers } from '../../redux/actions/requestAction';
import Loader from '../../components/Loader/Loader';
import UserCard from '../../components/UserCard/UserCard';
import UserModel from '../../components/UserModel/UserModel';
import { useSwipeable } from 'react-swipeable';

const SuggestedUsers = () => {
    const dispatch = useDispatch();
	const suggestedUsers = useSelector(state => state.request);
    const [currentUserIndex, setCurrentUserIndex] = useState(null);
    const handlers = useSwipeable({
        onSwipedLeft: () => setCurrentUserIndex((index) => (index + 1) % suggestedUsers?.suggestedUsers.length),
        onSwipedRight: () => setCurrentUserIndex((index) => (index - 1 + suggestedUsers?.suggestedUsers.length) % suggestedUsers?.suggestedUsers.length),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
      });

    const handleSuccess = () => {
        dispatch(getSuggestedUsers());
    }

    useEffect(() => {
        dispatch(getSuggestedUsers());

        //eslint-disable-next-line
    },[]);

    return (
        <div>
            <div className="mb-6 flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between">
                <h2 className="text-title-md2 font-semibold text-black dark:text-white">Suggestions</h2>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-9 py-4 md:py-6 2xl:py-10">
                {
                    suggestedUsers?.suggestedUsers?.map((user, index) => {
                        return (
                            <UserCard user={user} key={user._id} successAction={handleSuccess} setCurrentUserIndex={setCurrentUserIndex} index={index}/>
                        );
                    })
                }
               
            </div>
            {
                currentUserIndex !== null && <div {...handlers}><UserModel user={suggestedUsers?.suggestedUsers[currentUserIndex]} setCurrentUserIndex={setCurrentUserIndex} /></div>
            }
            <Loader loading={ suggestedUsers.loading } />
        </div>
    )
}

export default SuggestedUsers