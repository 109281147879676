import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineDown, AiOutlineUser } from "react-icons/ai";
import { BiLogOut } from "react-icons/bi";
import { useSelector } from 'react-redux';


const UserDropdownMenu = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const user = useSelector(state => state.user.user);
    const navigate = useNavigate();

    const trigger = useRef(null);
    const dropdown = useRef(null);

    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!dropdown.current) return;
            if (
                !dropdownOpen ||
                dropdown.current.contains(target) ||
                trigger.current.contains(target)
            )
                return;
            setDropdownOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
        if (!dropdownOpen || keyCode !== 27) return;
            setDropdownOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    const handleLogout = (e) => {
        e.preventDefault();

        localStorage.removeItem('access_token');
        navigate('/login');
    }

    return (
        <div className="relative">
            <Link
                ref={trigger}
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className="flex items-center gap-4"
                to="#"
            >
                <span className="hidden text-right lg:block">
                    <span className="block text-sm font-medium text-black dark:text-white">
                        { user.name }
                    </span>
                    <span className="block text-xs">{ user.email }</span>
                </span>

                <span className="h-12 w-12 rounded-full drop-shadow-2 overflow-hidden">
                    <div className="flex items-center justify-center w-12 h-12 rounded-full font-semibold text-xl text-black drop-shadow-2  bg-gray">
                        {
                            user?.images?.length > 0 ? ( <img src={user?.images[0]} className='rounded-full w-full' alt='user'/>) : ( <>
                                {user?.name?.split(' ')[0][0].toUpperCase()} { user?.name?.split(' ')?.[1]?.[0]?.toUpperCase()}
                                </>
                            )
                        }
                    </div>
                </span>

                <AiOutlineDown className={`hidden fill-current sm:block ${
                    dropdownOpen ? 'rotate-180' : ''
                }`}/>
            </Link>

            <div
                ref={dropdown}
                onFocus={() => setDropdownOpen(true)}
                onBlur={() => setDropdownOpen(false)}
                className={`absolute right-0 mt-4 flex w-62.5 flex-col rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark ${
                dropdownOpen === true ? 'block' : 'hidden'
                }`}
            >
                <ul className="flex flex-col gap-5 border-b border-stroke px-6 py-7.5 dark:border-strokedark">
                    <li>
                        <Link
                            to="/profile"
                            className="flex items-center gap-3.5 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
                        >
                           <AiOutlineUser />
                            My Profile
                        </Link>
                    </li>
                </ul>
                <button className="flex items-center gap-3.5 py-4 px-6 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base" onClick={handleLogout}>
                    <BiLogOut />
                    Log Out
                </button>
            </div>
        </div>
    );
};

export default UserDropdownMenu;
