import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EnvelopeIcon from '../../../assets/icons/EnvelopeIcon.js';
import { loginUser } from '../../../redux/actions/userAction.js';
import Loader from '../../../components/Loader/Loader.jsx';
import { Link, useNavigate } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import Logo from '../../../components/Logo/Logo.jsx';

const Login = () => {
	const dispatch = useDispatch();
  	const userLogin = useSelector(state => state.user);

	const navigate = useNavigate();

	const [loginData, setLoginData] = useState({
		email: '',
		password: '',
	});
	const [showPassword, setShowPassword] = useState(false);

	const { email, password } = loginData;

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(loginUser(loginData));
	};

	useEffect(() => {
		if(userLogin?.isLoggedIn){
			navigate('/dashboard');
		}

		//eslint-disable-next-line
	},[userLogin?.isLoggedIn]);

	return (
		<div className='flex justify-center items-center p-4 sm:p-10 min-h-screen bg-auth bg-cover bg-center'>
			<div className="w-full md:w-2/3 lg:w-1/2 xl:w-1/3 p-6 sm:p-0 rounded-sm border border-stroke bg-white bg-opacity-60 text-black shadow-default dark:border-strokedark dark:bg-boxdark h-fit">
				<div className="w-full border-stroke dark:border-strokedark">
					<div className="w-full p-4 sm:p-12.5 xl:p-17.5">
						<span className="mb-1.5 block font-medium"></span>
						<Logo />
						<h2 className="mb-9 text-2xl font-bold text-black dark:text-white sm:text-title-xl2 my-4 text-center">
							Log In
						</h2>
						<form onSubmit={handleSubmit}>
							<div className="mb-4">
								<label className="mb-2.5 block font-medium text-black dark:text-white">
									Email
								</label>
								<div className="relative">
									<input
										type="email"
										placeholder="Enter your email"
										className="w-full rounded-lg border border-stroke py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
										value={email}
										onChange={(e) => setLoginData({ ...loginData, email: e.target.value })}
									/>

									<span className="absolute right-4 top-4">
										<EnvelopeIcon />
									</span>
								</div>
								{
									userLogin?.errors?.email && <p className='text-sm text-danger'>{ userLogin?.errors?.email }</p>
								}
							</div>

							<div className="mb-6">
								<label className="mb-2.5 block font-medium text-black dark:text-white">
									Password
								</label>
								<div className="relative flex items-center">
									<input
										type={showPassword ? "text" : "password"}
										placeholder="Enter your password"
										className="w-full rounded-lg border border-stroke py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
										value={password}
										onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
									/>

									<button type='button' className="absolute right-4  cursor-pointer text-[#8E929A] text-xl flex items-center outline-none" onClick={(e) => {
										e.preventDefault();
										setShowPassword((prev) => !prev)
									}}>
										{
											showPassword ? <FiEyeOff /> : <FiEye />
										}
									</button>
								</div>
								{
									userLogin?.errors?.password && <p className='text-sm text-danger'>{ userLogin?.errors?.password }</p>
								}
							</div>

							<div className="mb-5">
								<input
									type="submit"
									value="Log In"
									className="w-full cursor-pointer rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90"
								/>
							</div>
						</form>
						<div className="mt-6 text-center"><p>Dont have an account? <Link className="text-primary" to="/join">Join</Link></p></div>
					</div>
				</div>
			</div>
			<Loader loading={userLogin?.loading} />
		</div>
	)
}

export default Login