"use client";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    requestAction,
    sendRequest,
    likeUser,
} from "../../redux/actions/requestAction";
import moment from "moment";
import { AiFillHeart } from "react-icons/ai";
import "react-image-gallery/styles/css/image-gallery.css";
import UserModel from "../UserModel/UserModel";

const UserCard = ({ user, successAction, setCurrentUserIndex, index }) => {
    const dispatch = useDispatch();
    const [showImages, setShowImages] = useState(false);

    const handleRequestSend = (e, user_id) => {
        e.preventDefault();
        dispatch(sendRequest(user_id, successAction));
    };

    const handleRequestAction = (e, status, user_id) => {
        e.preventDefault();
        dispatch(requestAction(user_id, status, successAction));
    };

    const handleLikeUser = (e) => {
        e.preventDefault();
        dispatch(likeUser(user._id, successAction));
    };

    return (
        <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark divide-y-2 divide-gray relative break-words">
            <div>
                <button
                    button="button"
                    className={`absolute text-4xl right-0 top-0 translate-x-1/2 -translate-y-1/2 ${
                        user.like ? "text-danger" : ""
                    }`}
                    onClick={handleLikeUser}
                >
                    <AiFillHeart />
                </button>
                <div className="p-6.5 flex gap-3">
                    <div className="w-1/3 rounded-full bg-white/20 p-1">
                        <div className="flex items-center">
                            <ProfileImage
                                user={user}
                                setCurrentUserIndex={setCurrentUserIndex}
                                index={index}
                                setShowImages={setShowImages}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col justify-center w-2/3 ">
                        <h3
                            className="mb-1.5 text-2xl font-medium text-black dark:text-white cursor-pointer"
                            onClick={() => {
                                if (setCurrentUserIndex) {
                                    setCurrentUserIndex(index);
                                } else {
                                    setShowImages(true);
                                }
                            }}
                        >
                            {user.name}
                        </h3>
                    </div>
                </div>
                <div className="px-6.5 pb-6.5 flex justify-center">
                    <div className="flex flex-wrap items-center">
                        {(user.receive_request_status === "accepted" ||
                            user.request_status === "accepted") && (
                            <p className="text-success">You are friends.</p>
                        )}
                        {user.receive_request_status === "pending" && (
                            <>
                                <button
                                    className="inline-flex items-center gap-2.5 border border-stroke py-1 px-2 font-medium text-black hover:border-primary hover:bg-primary hover:text-white dark:border-strokedark dark:text-white dark:hover:border-primary sm:py-2 sm:px-6 bg-success text-white"
                                    onClick={(e) => {
                                        handleRequestAction(
                                            e,
                                            "accepted",
                                            user._id
                                        );
                                    }}
                                >
                                    Accecpt
                                </button>
                                <button
                                    className="inline-flex items-center gap-2.5 border border-stroke py-1 px-2 font-medium text-black hover:border-primary hover:bg-primary hover:text-white dark:border-strokedark dark:text-white dark:hover:border-primary sm:py-2 sm:px-6 bg-danger text-white"
                                    onClick={(e) => {
                                        handleRequestAction(
                                            e,
                                            "rejected",
                                            user._id
                                        );
                                    }}
                                >
                                    Declined
                                </button>
                            </>
                        )}
                        {user.request_status === "pending" && (
                            <button
                                className="inline-flex items-center gap-2.5 border border-stroke py-1 px-2 font-medium text-black hover:border-primary hover:bg-primary hover:text-white dark:border-strokedark dark:text-white dark:hover:border-primary sm:py-2 sm:px-6 bg-danger text-white"
                                onClick={(e) => {
                                    handleRequestAction(e, "cancel", user._id);
                                }}
                            >
                                Cancel
                            </button>
                        )}
                        {user.request_status === "rejected" && (
                            <p className="text-danger">
                                You request was rejected.
                            </p>
                        )}
                        {user.receive_request_status === "rejected" && (
                            <p className="text-danger">
                                You have rejected the request.
                            </p>
                        )}
                        {user.receive_request_status === null &&
                            user.request_status === null && (
                                <button
                                    className="inline-flex items-center gap-2.5 border border-stroke py-1 px-2 font-medium text-black hover:border-primary hover:bg-primary hover:text-white dark:border-strokedark dark:text-white dark:hover:border-primary sm:py-2 sm:px-6 bg-success text-white"
                                    onClick={(e) => {
                                        handleRequestSend(e, user._id);
                                    }}
                                >
                                    Send Request
                                </button>
                            )}
                    </div>
                </div>
                {showImages && (
                    <UserModel
                        user={user}
                        setCurrentUserIndex={setShowImages}
                    />
                )}
            </div>
            <Biodata user={user} />
        </div>
    );
};

const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = degreesToRadians(lat2 - lat1);
    const dLon = degreesToRadians(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(degreesToRadians(lat1)) *
            Math.cos(degreesToRadians(lat2)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return (R * c).toFixed(2); // Distance in kilometers
};

const degreesToRadians = (degrees) => {
    return degrees * (Math.PI / 180);
};

export const Biodata = ({ user }) => {
    const auth_user = useSelector((state) => state.user.user);

    return (
        (user.bio || user.dob || user.state) && (
            <div className="p-6.5">
                {user.bio && (
                    <div className="mb-4">
                        <h6 className="text-medium">Bio</h6>
                        <p className="text-sm line-clamp-5" title={user.bio}>
                            {user.bio}
                        </p>
                    </div>
                )}
                {user.dob && (
                    <div className="flex gap-2 justify-between">
                        <h6 className="text-sm">Age</h6>
                        <p className="text-sm ">
                            {" "}
                            {moment().diff(user.dob, "years")} years{" "}
                        </p>
                    </div>
                )}
                {user.state && (
                    <div className="flex gap-2 justify-between items-center">
                        <h6 className="text-sm">Address</h6>
                        <p className="text-sm ">
                            {" "}
                            {` ${user.city}${user.city ? ", " : ""} ${
                                user.state
                            }`}{" "}
                        </p>
                    </div>
                )}
                {user?.location?.lat &&
                    user?.location?.long &&
                    auth_user?.location?.lat &&
                    auth_user?.location?.long && (
                        <div className="flex gap-2 justify-between items-center">
                            <h6 className="text-sm">Last Location:</h6>
                            <p className="text-sm ">
                                {" "}
                                {calculateDistance(
                                    user.location.lat,
                                    user.location.long,
                                    auth_user.location.lat,
                                    auth_user.location.long
                                )}{" "}
                                Km away
                            </p>
                        </div>
                    )}
            </div>
        )
    );
};

const ProfileImage = ({ user, setCurrentUserIndex, index, setShowImages }) => {
    return (
        <div key={user._id}>
            <div
                className="flex items-center justify-center w-20 h-20 rounded-full font-semibold text-4xl text-black bg-gray relative cursor-pointer overflow-hidden"
                onClick={() => {
                    if (setCurrentUserIndex) {
                        setCurrentUserIndex(index);
                    } else {
                        setShowImages(true);
                    }
                }}
            >
                {user?.images?.length > 0 ? (
                    <img
                        src={user?.images[0]}
                        className="rounded-full w-full"
                        alt="user"
                    />
                ) : (
                    <>
                        {user?.name?.split(" ")[0][0].toUpperCase()}{" "}
                        {user?.name?.split(" ")?.[1]?.[0]?.toUpperCase()}
                    </>
                )}
            </div>
        </div>
    );
};

export default UserCard;
